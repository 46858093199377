<template>
  <v-footer
    class="ctk-footer"
  >
    <template>
      <v-container grid-list-md text-center pt-5>
        <v-layout wrap>
          <v-flex xs12 sm12 md3 lg3 pl-2 class="hidden-sm-and-down">
            <div style="text-align:right;"><img :src="info.logo" style="height:84px;"/></div>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 pl-2 class="hidden-sm-and-down">
            <v-layout wrap>
              <v-flex md1></v-flex>
              <v-flex md2 class="text-center footer-m">
                <a class="footer-menu" href="/about">公司介绍</a>
              </v-flex>
              <v-flex md2 class="text-center footer-m">
                <a class="footer-menu" href="/products">产品中心</a>
              </v-flex>
              <v-flex md2 class="text-center footer-m">
                <a class="footer-menu" href="/cases">案例中心</a>
              </v-flex>
              <v-flex md2 class="text-center footer-m">
                <a class="footer-menu" href="/quality">关于品质</a>
              </v-flex>
              <v-flex md2 class="text-center footer-m">
                <a class="footer-menu" href="/news">新闻动态</a>
              </v-flex>
              <v-flex md1></v-flex>
              <v-flex md12>
                <ul class="footer-links">
                  <li>友情链接:</li>
                  <li v-for="link in info.links" :key="link.id" :href="link.url">
                    <a :href="link.url">{{ link.title }}</a>
                  </li>
                </ul>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md3 lg3 pl-2 class="hidden-sm-and-down">
            <h5 class="footer-title text-left">联系方式</h5>
            <ul class="footer-contact text-left">
              <li>
                <span>{{this.$t("footer.telephone")}}：</span><span>{{info.tel}}</span>
              </li>
              <li>
                <span>{{this.$t("footer.email")}}：</span><span>{{info.email}}</span>
              </li>
              <li>
                <span>{{this.$t("footer.address")}}：</span><span>{{info.addr}}</span>
              </li>
            </ul>
          </v-flex>
          <v-flex xs12 class="ctk-footer-copyright">
            &copy;2021 上海彩覆塑料型材有限公司 | <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">备案号: {{info.icp}}</a>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
  }),
  computed: {
    info () {
      let links = []
      if (this.$store.getters.companyInfo.link) {
        links = this.$store.getters.companyInfo.link.filter(ele => ele.type === 0)
      }
      let info = this.$store.getters.companyInfo
      info.links = links
      return info
    }
  }
}
</script>
